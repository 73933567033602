.sticky-action-row {
  background-color: inherit;
  border-color: rgb(var(--theme-palette-neutral-bg-strong));

  &:hover {
    background-color: rgb(var(--theme-palette-neutral-bg-default));
  }

  .sticky-action-cell {
    background-color: inherit;
  }
}