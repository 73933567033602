div.form-size-estimator.instance-details {
  display: grid;
  gap: 16px;
  padding-right: 16px;
  grid-template-columns: 50% 50%;
}
div.form-size-estimator.instance-details {
  grid-template-areas:
    'nodes relationships';
}

div.instance-details > div.nodes {
  grid-area: nodes;
}

div.instance-details > div.relationships {
  grid-area: relationships;
}
