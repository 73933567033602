.StripeElement {
  box-sizing: border-box;
  height: 2.25rem;
  border-color: rgb(var(--theme-palette-neutral-border-strong));
  border-width: 1px;
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
}

.StripeElement--focus {
  border-color: rgb(var(--theme-palette-primary-focus));
  outline-style: solid;
  outline-color: rgb(var(--theme-palette-primary-focus));
  outline-width: 1px;
}

.console-billing-section {
  margin-top: 1.5rem; 
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  flex-direction: row;
}

.console-email-section {
  margin-top: 1.5rem; 
  margin-bottom: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  flex-direction: row;
}
