:root {
  --gradient: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.95) 85%
            );
}

.ndl-theme-dark {
  --gradient: linear-gradient(
            to bottom,
            rgba(33, 35, 37, 0),
            rgba(33, 35, 37, 0.95) 85%
            );
}

.console-tsandcs {
    overflow-y: auto;
    max-height: calc(100vh - 26rem);

    &.console-tsandcs-compressed {
        cursor: pointer;
        overflow-y: hidden;
        max-height: 10rem;
        &:after {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            pointer-events: none;
            background-image: var(--gradient);
            width: 100%;
            height: 100%;
        }
    }

    &.console-tsandcs-with-marketing-section {
        max-height: calc(100vh - 30rem);
    }
}

.console-tsandcs-arrow {
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    transform: rotate(180deg);
    top: 8.5em;
    left: 50%;
    pointer-events: none;
    z-index: 2;
}
