div.form-profile-information.profile-information {
  display: grid;
  gap: 24px;
  padding-right: 16px;
  grid-template-columns: 50% 50%;
}

div.profile-information > div.first-name {
  grid-column: span 1;
}

div.profile-information > div.last-name {
  grid-column: span 1;
}

div.profile-information > div.role {
  grid-column: span 2;
}

div.profile-information > div.role-other {
  grid-column: span 2;
}

div.profile-information > div.primary-use-case {
  grid-column: span 2;
}

div.profile-information > div.primary-use-case-other {
  grid-column: span 2;
}

div.profile-information > div.company-name {
  grid-column: span 2;
}

div.profile-information > div.country {
  grid-column: span 2;
}

div.profile-information > div.sub-division {
  grid-column: span 2;
}

div.profile-information > div.error {
  grid-column: span 2;
}

div.profile-information > div.actions {
  grid-column: span 2;
}
