.db-sizes-table .ndl-data-grid-tr {
  & .ndl-data-grid-td {
    background-color: inherit;
    height: 40px;

    &.ndl-data-grid-td-disabled {
      color: rgb(var(--theme-palette-neutral-text-weaker))
    }

    &.ndl-data-grid-td-selected {
      background-color: inherit;
    }
  }
}

