.console-accordion {
  position: relative;
  & .console-accordion-icon {

    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    &.console-icon-open {
      transform: rotate(-180deg);
    }
    &:not(.console-icon-open) {
      transform: rotate(0deg);
    }
    &.left {
      margin-right: 16px;
      margin-left: 16px; 
    }
    
    &.right {
      position: absolute;
      right: 20px;
    }
  }

  & .console-accordion-title {
    cursor: pointer;
    border-radius: 8px;
    outline-radius: 8px;
    &:hover {
      background-color: rgb(var(--theme-palette-neutral-hover));
    }
    &:focus {
      outline: 2px solid rgb(var(--theme-palette-primary-border-strong));
    }
    &.disabled {
      cursor: not-allowed;
      color: rgb(var(--theme-palette-neutral-text-weakest));
    }
  }

  & .console-accordion-content {
    transition-property: max-height, opacity, visibility;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
}
