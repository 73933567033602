* {
  padding: 0;
  margin: 0;
}

:root {
  --console-border-color: unset;
  --console-border-width: 0;
}

.ndl-theme-dark {
  --console-border-color: rgb(var(--theme-palette-neutral-border-strong));
  --console-border-width: 1px;
}

html,
body {
  @apply tw-text-base;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#reactContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

body {
  background-color: rgb(var(--theme-palette-neutral-bg-default));
  color: rgb(var(--theme-palette-neutral-text-default));
  min-width: 700px; /* just enough to fit all top nav elements */

  /* 
     When the menu component is opened, it disables scroll 
     and sets a padding-right equal to the scroll bar width
     to avoid content shifting due to scroll being disabled.
     Because of the above width: 100vw, the scrollbar
     never pushes content and the menu components padding
     causes the webpage to move when a menu is opened. 
     We need to override the menu components padding-right 
     such that it will have no effect and we can avoid
     this content push caused by the menu,
     hence the !important
  */
  padding-right: 0 !important; 
}

a {
  color: rgb(var(--theme-palette-primary-text));
  text-decoration: underline;
}

.ndl-theme-dark .ndl-popover {
  border-color: var(--console-border-color);
}

.console-border-color {
  border-color: var(--console-border-color);
  border-width: var(--console-border-width);
}

/* tw-w-fit not available in 2.x */
.console-w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

/* Same as .ndl-modal.medium */
.console-max-w-medium {
  max-width: 47rem;
}

/* Same as .ndl-modal.medium */
.console-max-w-large {
  max-width: 60rem;
}

.ndl-table-root.console-rows-min-height-56 .ndl-table-tbody .ndl-table-tr {
  min-height: 56px;
}

div.console-code-box {
  font-family: monospace;
  word-break: break-word;
  white-space: pre-wrap;
  position: relative;
  min-height: 1em;
  margin: 1em 0em;
  background: #f8f8f9;
  padding: 1em 1.5em;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
  border-radius: 0.28571429rem;
  box-shadow: 0px 0px 0px 1px rgb(34 36 38 / 22%) inset, 0px 0px 0px 0px rgb(0 0 0 / 0%);
}

span.console-mono {
  font-family: monospace;
  word-break: break-word;
  white-space: pre-wrap;
}

*:not([class*="ndl-"]):not(input) {
  &:focus-visible {
    &:not([class*="-card"], button) {
      border-radius: var(--border-radius-sm);
    }
    border-color: rgb(var(--theme-palette-primary-focus));
    outline-style: solid;
    outline-color: rgb(var(--theme-palette-primary-focus));
    outline-width: 2px;
  }
}

*::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(var(--theme-palette-neutral-bg-strong));
  border-radius: 10px;
}


@keyframes console-party-animation {
  0% {
      filter: sepia(1) hue-rotate(-30deg) saturate(600%) contrast(120%);
  }
  100% {
      filter: sepia(1) hue-rotate(330deg) saturate(600%) contrast(120%);
  }
}
