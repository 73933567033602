div.form-self-serve.instance-details {
  display: grid;
  gap: 16px;
  padding-right: 16px;
  grid-template-columns: 50% 50%;
}

div.form-self-serve.instance-details {
  grid-template-areas:
    'name     version';
}

div.form-self-serve.instance-details.with-partner-account {
  grid-template-areas:
    'name   version'
    'partner-id partner-id';
}

div.instance-details > div.name {
  grid-area: name;
}

div.instance-details > div.version {
  grid-area: version;
}

div.instance-details > div.partner {
  grid-area: partner-id;
}

div.instance-details > div.spd-enabled {
  grid-column: 1 / -1;
}

div.instance-details > div.spd-enable-writes {
  grid-column: 1 / -1;
}

div.instance-details > div.spd-config-seed-type {
  grid-column: 1 / -1;
}

div.instance-details > div.spd-config-graph-seed-uri {
  grid-column: 1 / -1;
}

.console-cloud-provider-card {
  border-radius: var(--border-radius-3xl);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    box-shadow: var(--theme-shadow-raised);
  }
  &:focus {
    outline: 1px solid rgb(var(--theme-palette-primary-border-strong));
  }
}
