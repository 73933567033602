.profile-popup-alert > .ndl-banner-content {
    .ndl-banner-title {
        @apply tw-text-base;
        font-weight: bold;
    }

    .ndl-banner-description {
        @apply tw-text-sm;
    }
}

#profile-email {
    color: rgb(var(--theme-palette-neutral-text-weaker));
}

#profile-email, #profile-name {
    text-overflow: ellipsis;
}

#profile-popup-image {
    height: 3.4rem;
    width: 3.4rem;
    min-width: 3.4rem;
}

.workspace-switch-link {
    color: rgb(var(--theme-palette-neutral-text-default));
    font-weight: bold;
    cursor: pointer;
}

#profile-popup {
    width: 26rem;
    transform: translateY(1rem);
    box-shadow: 0rem 0.5rem 1.25rem rgba(12, 26, 37, 0.12);
    border: 1px solid var(--console-border-color);
    z-index: 2001;
}

.profile-image {
    border-radius: 50%;
}

.profile-item {
    display: flex;
}
