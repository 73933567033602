.console-toast {
  position: fixed;
  top: var(--space-14);
  right: var(--space-10);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 2000; /* So its above semantic ui react dimmer */

  & .toast {
    opacity: 0;
    transition: opacity .5s ease;
    margin-bottom: var(--margin-3);
    max-width: 43rem;
    min-width: 25rem;

    &.show {
      opacity: 1;
    }

    & .ndl-banner-content {
      & .ndl-banner-description, .ndl-banner-actions {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}