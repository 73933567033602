.admin-tenant-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--space-6);
}

.admin-tenant-button {
  margin-right: auto;
}
