:root {
  --space-0.5: 0.125rem;
  --space-1: 0.25rem;
  --space-2: 0.5rem;
  --space-3: 0.75rem;
  --space-4: 1rem;
  --space-5: 1.25rem;
  --space-6: 1.5rem;
  --space-8: 2rem;
  --space-10: 2.5rem;
  --space-12: 3rem;
  --space-14: 3.5rem;
  --space-16: 4rem;
  --space-20: 5rem;
  --space-24: 6rem;
  --space-32: 8rem;
 
  /* padding */
  --padding-0.5: var(--space-0.5);
  --padding-1: var(--space-1);
  --padding-2: var(--space-2);
  --padding-3: var(--space-3);
  --padding-4: var(--space-4);
  --padding-5: var(--space-5);
  --padding-6: var(--space-6);
  --padding-8: var(--space-8);
  --padding-10: var(--space-10);
  --padding-12: var(--space-12);
  --padding-14: var(--space-14);
  --padding-16: var(--space-16);
  --padding-20: var(--space-20);
  --padding-24: var(--space-24);
  --padding-32: var(--space-32);

  /* margin */
  --margin-0.5: var(--space-0.5);
  --margin-1: var(--space-1);
  --margin-2: var(--space-2);
  --margin-3: var(--space-3);
  --margin-4: var(--space-4);
  --margin-5: var(--space-5);
  --margin-6: var(--space-6);
  --margin-8: var(--space-8);
  --margin-10: var(--space-10);
  --margin-12: var(--space-12);
  --margin-14: var(--space-14);
  --margin-16: var(--space-16);
  --margin-20: var(--space-20);
  --margin-24: var(--space-24);
  --margin-32: var(--space-32);

}
