.request-logs-tab {
    .time-period {
        text-transform: none;
    }

    .ndl-table-tr {
        height: 56px;
        & .download-logs {
            visibility: hidden;
        }
        &:hover {
            & .download-logs {
                visibility: visible;
            }
        }
    }
}

.request-logs-dialog {
    .ndl-form-item {
        margin-top: var(--margin-1);
        margin-bottom: var(--margin-1);
    }

    .ndl-alert .alert-description {
        margin: 0;
    }

    .slider-value-display {
        @apply tw-text-sm tw-font-normal;
        color: rgb(var(--theme-palette-neutral-text-weaker));
        font-variant-numeric: tabular-nums;
    }

    &.ndl-modal.small {
        max-width: 30rem;
    }
}
