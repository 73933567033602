.console-page {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  background-color: rgb(var(--theme-palette-neutral-bg-default));

  > .console-content {
    padding-left: var(--space-6);
    padding-right: var(--space-6);
    flex-grow: 1;
    overflow-y: auto;

    & > div {
      margin: 0 auto;
      max-width: 1000px;
    }
  }

  & .ndl-side-navigation {
    & a {
      color: inherit;
      text-decoration: none;
    }
  }

  & .connect-list {
    color: rgb(var(--theme-palette-neutral-text-weak));
  }
}

.top-nav {
  width: 100%;
  height: var(--space-16);
  min-height: var(--space-16);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--space-6) 0 var(--space-6);
  z-index: 3;

  & .logo {
  }

  & .left-items {
    display: flex;
    align-items: center;
    gap: var(--space-6);
  }

  & .right-items {
    display: flex;
    flex-wrap: none;
    align-items: center;
    gap: var(--space-2);

    & .vertical-divider {
      margin-left: var(--space-8);
      margin-right: var(--space-8);
      width: 1px;
      height: var(--space-8);
      background-color: rgb(var(--colors-neutral-50));
    }
  }
  & .console-label {
    color: rgb(var(--colors-neutral-75));
    background: rgb(var(--colors-lemon-30));
  }
}

.ndl-side-navigation {
  &:not(.ndl-expanded) {
    cursor: pointer;
  }

  & .ndl-side-navigation-nav-list .ndl-side-navigation-nav-item .ndl-side-navigation-inner-item:not(.ndl-side-navigation-group-header) {
    &:not(.ndl-selectable) {
      color: rgb(var(--theme-palette-neutral-text-weakest));
      cursor: not-allowed;
    }
  }
}
