.single-line-codeblock {
  background: rgb(var(--background));
  border-color: rgb(var(--border));
  position: relative;
  border-radius: var(--border-radius-lg);
  border-width: 1px;
  padding: var(--space-3);

  & > code {
    font-weight: normal;
  }
}

.single-line-codeblock-action {
  position: absolute;
  top: var(--border-radius-lg);
  right: var(--border-radius-lg);
}
