.console-breadcrumbs {
  color: rgb(var(--theme-palette-neutral-text-weaker));
  & .console-link {
    color: rgb(var(--theme-palette-neutral-text-weaker));
    text-decoration-line: none;
  }

  & :not(:last-child):after {
      margin-left: 8px;
      margin-right: 8px;
      content: "/";
  }
  
  &>:last-child {
    color: rgb(var(--theme-palette-neutral-text-default));
    & .console-link {
      color: rgb(var(--theme-palette-neutral-text-default));
    }
  }
}
