.Canny_BadgeContainer .Canny_Badge {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 6px;
    height: 6px;
    background-color: rgb(var(--theme-palette-primary-bg-status));
    border: none;
    padding: 0;
}

#canny-changelog-iframe {
    position: fixed !important;
    top: 60px !important;
}