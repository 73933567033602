.console-icon, .ndl-icon {
  &.large {
    width: 1.2rem;
    height: 1.2rem;
  }

  &.regular {
    width: 1.1rem;
    height: 1.1rem;
  }

  &.small {
    width: 1rem;
    height: 1rem;
  }

  &.mini, &.x-small {
    width: 0.8rem;
    height: 0.8rem;
  }

  &.xx-small {
    width: 0.5rem;
    height: 0.5rem;
  }
}
