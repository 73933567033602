.slider-container {
    display: flex;
    position: relative;
    align-items: center;
    height: 2rem;

    .slider {
        height: 0.3rem;
        border-radius: 0.15rem;
    }
    .slider-0 {
        background: rgb(var(--theme-palette-primary-bg-strong));
    }
    .slider-1 {
        background: rgb(var(--theme-palette-neutral-bg-strong));
    }
    &.disabled .slider-0 {
        background: rgb(var(--theme-palette-neutral-bg-stronger));
    }
    .stud {
        width: 1rem;
        height: 1rem;
        border-radius: 1rem;
        background: lightgrey;
    }
}