:root {
  --backdrop-opacity: 0;
  --backdrop-pointer-event: none;
}

.backdrop-visible {
  --backdrop-opacity: 0.4;
  --backdrop-pointer-event: auto;
}

.console-backdrop {
  pointer-events: var(--backdrop-pointer-event);
  z-index: 2;
  background-color: rgb(0, 0, 0);
  opacity: var(--backdrop-opacity);
  transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 150ms;
}