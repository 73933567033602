/* 
  Fix bug where modal is not on top of menu elements.
  Prevents nesting of menus in modals though. This
  is an acceptable tradeoff according to the design team
  
  Can remove when it is finally merged in NDL
  https://trello.com/c/7aJpZIAI/471-use-the-same-z-index-for-popup-modal-backdrops-and-content
*/
.ndl-modal-root.ndl-modal-root {
  z-index: 1100;
}