.console-db-card {
  text-decoration: none; /* Reset from <a> color */
  padding: var(--padding-6);
  border-radius: var(--border-radius-3xl);
  border-color: var(--console-border-color);
  border-width: var(--console-border-width);
  width: 100%;
  min-height: 260px;
  transition: box-shadow 75ms ease;
  display: grid;
  grid-template-columns: 1fr 1fr min-content;
  grid-template-rows: auto;
  grid-template-areas: 
    "card-title card-title card-buttons"
    "card-id card-id card-buttons"
    "card-status card-status card-buttons"
    "card-info card-info card-buttons"
    "card-info card-info card-actions";
  &.interactive {
    cursor: pointer;
  }

  &:focus-visible {
    outline: none;
  }

  & .db-card-title {
    grid-area: card-title;
  }

  & .db-card-buttons {
    grid-area: card-buttons;
    display: flex;
    flex-direction: column;
    gap: var(--space-2);
    align-items: flex-end;
    justify-self: end;
    margin-left: var(--margin-2);
    margin-bottom: var(--margin-2);
    white-space: nowrap;
    max-width: 100px;

    & .db-legacy-buttons {
      min-width: calc(5.25rem + var(--space-2) * 2); /* The width of 3 action buttons with spacing */
    }
  }

  & .db-card-info {
    grid-area: card-info;
    min-width: 0; /* (Firefox) Required to make flex items shrink below their minimum content size */
  }

  & .db-card-status {
    grid-area: card-status;
  }

  & .db-card-id {
    grid-area: card-id;
  }

  & .db-card-actions {
    grid-area: card-actions;
    display: flex;
    justify-content: flex-end;
  }

  &:hover {
    /* color: rgb(var(--text-primary)); Reset from <a> color */
    text-decoration: none; /* Reset from <a> color */

    &.rr {
      background-image: url("components/ui/images/rick.gif");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &.console-party {
    &:hover {
      animation: linear console-party-animation 1s infinite;
    }
  }

  & .db-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & .db-info {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;

    & .info-title {
      color: rgb(var(--theme-palette-neutral-text-weaker));
      margin-right: var(--margin-2);
    }
  }

  & .db-card-label {
    height: 1.5rem;
    font-size: 14px;
    line-height: 20px;
    border-color: rgb(var(--theme-palette-neutral-border-weak));
    border-width: 1.25px;

    & .label-content {
    }
  }
}
