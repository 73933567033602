.console-marketplace-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
}

.console-marketplace-loading-container {
    margin-top: 50px;
}
