.console-algorithm-category-card {
    text-align: left;
    padding: var(--padding-4);
    border-radius: var(--border-radius-3xl);
    position: relative;
    cursor: pointer;
    transition: border-color 0.1s, color 0.1s, opacity 0.1s, filter 0.1s;
    margin: 0;
    border: 1px solid rgb(var(--theme-palette-neutral-border-strong));
    box-shadow: 0px 0px 2px rgb(52 58 67 / 10%), 0px 1px 2px rgb(52 58 67 / 8%),
    0px 0px 4px rgb(52 58 67 / 8%);
    height: 200px;
    min-width: 232px;

    &.disabled {
        filter: grayscale(100%);
        opacity: 0.45;
        color: rgb(var(--theme-palette-neutral-text-default));
    }

    &[aria-checked='true'] {
        border-color: rgb(var(--theme-palette-primary-border-strong));
    }

    &:hover {
        box-shadow: 0px 0px 2px rgb(52 58 67 / 10%), 0px 2px 5px rgb(52 58 67 / 8%),
            0px 5px 15px rgb(52 58 67 / 8%);
    }
}

.console-algorithm-category-card-checked {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgb(var(--theme-palette-primary-border-strong));
    border-radius: 50px;
    height: 20px;
    width: 20px;
    padding: 3px;
    color: rgb(var(--theme-palette-neutral-text-inverse));
}