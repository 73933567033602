
/**
 * Overrides
 */
:root {
  --date-picker-border-color: none;
}

.ndl-theme-dark {
  --date-picker-border-color: rgb(var(--theme-palette-neutral-border-strong));
}

.react-datepicker__current-month, .react-datepicker__day {
  color: rgb(var(--theme-palette-neutral-text-default));
}

.react-datepicker__day:hover {
  border-radius: 50%;
  background-color: rgb(var(--theme-palette-neutral-bg-strong));
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: rgb(var(--theme-palette-primary-bg-strong));
  color: rgb(var(--theme-palette-neutral-text-inverse));
  border-radius: 50%;

  &:hover {
    background-color: rgb(var(--theme-palette-primary-hover-strong));
  }
}

.react-datepicker__time-list-item--selected {
  background-color: rgb(var(--theme-palette-primary-bg-strong)) !important;
  color: rgb(var(--theme-palette-neutral-text-inverse));

  &:hover {
    background-color: rgb(var(--theme-palette-primary-hover-strong)) !important;
  }
}

.react-datepicker__day--outside-month {
  color: rgb(var(--theme-palette-neutral-text-weakest));
}

.react-datepicker-time__caption {
  display: none !important;
}

/* THIS IS NOT INSANITY. THIS IS BEM */
/* Can you guess which div is the parent? */
.react-datepicker-time__input-container {
  margin: 0;
  display: flex !important;
  justify-content: center;
  border-top: 1px solid rgb(var(--theme-palette-neutral-border-weak));
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}
.react-datepicker__input-time-container {
  margin: 0;
  display: block;
}

/* Yes, the wrapper and the input have the same class */
div.react-datepicker-time__input {
  border: 1px solid rgb(var(--theme-palette-neutral-border-strong));
  border-radius: 0.25rem;
  margin: 0 !important;
  padding: var(--padding-1);
  font-size: var(--large-font-size);
  font-weight: var(--large-font-weight);
  line-height: var(--large-line-height);

  & > input[type=time] {
    cursor: text;
    outline: none;
    background-color: rgb(var(--theme-palette-neutral-bg-weak));
    & #picker {
      cursor: poiner;
    }
  }
}

.react-datepicker__header {
  background-color: rgb(var(--theme-palette-neutral-bg-weak));
  border-bottom: 1px solid rgb(var(--theme-palette-neutral-border-weak));
}

.react-datepicker__day-name {
  color: rgb(var(--theme-palette-neutral-bg-strongest));
}

.react-datepicker {
  background-color: rgb(var(--theme-palette-neutral-bg-weak));
  color: rgb(var(--theme-palette-netural-text-default));
  border: none;
}

/**
 * Regular custom css
 */

.console-daterange-time-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: var(--space-2);
  margin: var(--margin-2);
  width: 265px;
}

.console-daterange-popover > div:first-child {
  display: flex;
  justify-content: center;
}

.ndl-popover.console-datepicker-popover {
  border-color: var(--date-picker-border-color);
}
