/**
* Console icons come with predefined sizes. 
* and the menu item icon expects to set the size itself
* which causes issues, so we unset the menu item icon 
* size here
*/
.console-menu-item.ndl-menu-item .ndl-menu-item-icon {
  width: auto;
  height: auto;
}

a.console-menu-item.ndl-menu-item {
  text-decoration: none;
  color: inherit;
}

.ndl-popover-backdrop {
  cursor: default;
}
