.create-db {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.create-db-self-serve-page {
  width: fit-content;
  margin: var(--margin-3);
}

.detail-metrics-tab-container {
  display: flex;
}

.details-tab-panels {
  box-shadow: var(--theme-shadow-raised);
  background-color: rgb(var(--theme-palette-neutral-bg-weak));
  padding: var(--padding-6);
  border-bottom-right-radius: var(--border-radius-lg);
  border-bottom-left-radius: var(--border-radius-lg);
  border-top-right-radius: var(--border-radius-lg);
}

.db-details-header {
  & .db-info {
    @apply tw-text-sm;
    display: flex;
    flex-wrap: nowrap;

    & .info-title {
      color: rgb(var(--theme-palette-neutral-text-weaker));
      margin-right: var(--margin-2);
      white-space: nowrap;
    }

    & .info-value {
      white-space: nowrap;
    }
  }

  & .connection-uri-copy-icon {
    padding: 0 !important;
    width: auto !important;
  }
}

.db-cards {
  margin-top: var(--space-6);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));
  grid-gap: var(--space-6);

  & .connection-uri {
    display: flex;
    align-items: center;
  }

  & .connection-uri-copy-icon {
    margin-left: var(--margin-2);
    margin-right: var(--margin-2);
  }
}

.back-to-databases {
  color: inherit;
  text-decoration: none;
}

.db-card {
  min-width: 480px;
  max-width: 580px;
}

.db-card-sizing {
  width: 100%;
  display: flex;
}

.db-details-header-top-row {
  @media only screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }

  & .console-party {
    animation: linear console-party-animation 1s infinite;
  }
}
