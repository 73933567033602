.user-management-wrapper {
  background: rgb(var(--theme-palette-neutral-bg-weak));
  flex-grow: 1;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;

  & > section {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    margin-bottom: var(--space-6);
    display: flex;

    & > * {
      display: flex;
      align-self: center;
    }

    & > *:not(:last-child) {
      flex-grow: 1;
    }
  }

  .ndl-table tr th.table-column-styling {
    /* This should be a no-op. It's just here to ensure it doesn't fall out
     * of line with the padding on the lede.
     */
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .pending {
    color: rgb(var(--theme-palette-neutral-text-weakest));
  }

  .ndl-table .ndl-table-placeholder {
    padding-left: 1.75rem;
    top: auto !important;
    right: auto !important;
    left: 0 !important;
    transform: none !important;
    align-self: flex-start;
    
    & * {
      font-size: var(--font-size-body-large);
      color: rgb(var(--theme-palette-neutral-text-weaker));
    }
  }
}