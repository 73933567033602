.preference-items {
  & .preference-item:not(:last-child) {
    border-bottom: 1px solid rgb(var(--theme-palette-neutral-border-strong));
  }
}

.organization-info {
  display: flex;
  align-items: center;
  gap: 30px;
}

.organization-info-item {
  display: flex;
  flex-direction: column;
}
