.console-button {
  & .button-icon {

    &.disabled {
      cursor: default;
    }
  }
}

.ndl-icon {
  & .ndl-status-indicator {
    svg {
      width: 6px;
      height: 6px;
    }
  }
}