.tenant-switcher-menu {
    .console-menu-item-group {
        .ndl-menu-subheader-title {
            font-weight: var(--font-weight-bold);
        }
    }

    .console-menu-item {
        .ndl-menu-item-title {
            font-weight: var(--h1-font-weight);
        }

        .copy-to-clipboard {
            visibility: hidden;
        }

        &.selected, &:hover, &:focus {
            border-radius: var(--border-radius-lg);
            background-color: rgb(var(--theme-palette-primary-bg-weak)) !important;

            .ndl-menu-item-title,
            .ndl-menu-item-description {
                color: rgb(var(--theme-palette-primary-text));
            }
        }
        &:hover {
            .ndl-menu-item-description {
                & .copy-to-clipboard {
                    visibility: visible;
                }
            }
        }
    }
}

.enterprise-label {
    color: rgb(var(--colors-neutral-50));
    background: rgb(var(--colors-lemon-30));
}
