.ndl-side-navigation.settings-sidenav {
  width: 284px;
  min-width: 284px;
  background-color: rgb(var(--theme-palette-neutral-bg-default));

  @media(max-width: 1023px) {
    width: 140px;
    min-width: 140px;
  }
  
  &.ndl-left {
    border-right: none;
  }
  & .ndl-side-navigation-drawer-button {
    display: none;
  }

  & .ndl-side-navigation-nav {
    padding-top: 0;
  }

  & .ndl-side-navigation-nav-item {
    margin-left: 0;
    & > .ndl-side-navigation-inner-item {
      &.ndl-selectable {
        &:hover {
          &:not(.ndl-selected) {
            background-color: rgb(var(--theme-palette-neutral-bg-strong));
            alpha: 0.5;
          }
        }

        &:focus-visible {
          background-color: rgb(var(--theme-palette-neutral-bg-strong));
          color: rgb(var(--light-primary-text));

          outline: auto;
        }

        &:active {
          background-color: rgb(var(--theme-palette-neutral-bg-strong));
          color: rgb(var(--theme-palette-neutral-text-default));
          font-weight: 700;
        }
      }
      &.ndl-selected {
        background-color: rgb(var(--theme-palette-neutral-bg-strong));
        color: rgb(var(--theme-palette-neutral-text-default));
        font-weight: 700;
      }
    }
  }
}
