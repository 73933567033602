/* A lot of this CSS is directly copy pasted from 
Semantic, hence the rem usages etc */

.console-list-description {
  display: block;
  color: rgba(0, 0, 0, 0.7);
}

.console-list {
  margin: 1rem 0;
  position: relative;

  & li {
    padding-bottom: var(--padding-1);
  }

  /* Nested */
  & .console-list {
    clear: both;
    margin: 0em;
    padding: 0.75em 0em 0.25em 0.5em;
  }

  &.console-list-bulleted {
    list-style-type: disc;
    margin-left: var(--margin-4);
    margin-top: var(--margin-3);
    margin-bottom: var(--margin-3);
  }

  &.console-list-ordered {
    list-style-type: none;
    counter-reset: ordered;
    margin-left: 1.25rem;

    & .console-list {
      counter-reset: ordered;
      margin-left: 1rem;

      & li::before{ 
        margin-left: -2rem;
      }
    }

    & li::before{
      position: absolute;
      top: auto;
      left: auto;
      user-select: none;
      pointer-events: none;
      margin-left: -1.25rem;
      counter-increment: ordered;
      content: counters(ordered, ".") " ";
      text-align: right;
      color: rgba(0, 0, 0, 0.87);
      vertical-align: middle;
      opacity: 0.8
    }
  }
}