.console-status {
  display: flex;
  align-items: center;

  & .neutral {
    color: rgb(var(--colors-neutral-50));
  }

  & .status-text {
    &:first-letter {
      text-transform: capitalize;
    }
  }

  & .setting-up-text {
    line-height: 0.9rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    
    & em {
      color: rgb(var(--theme-palette-neutral-text-weaker))
    }
  }
}
