div.form-enterprise.instance-details {
  display: grid;
  gap: 16px;
  padding-right: 16px;
  grid-template-columns: 50% 50%;
}

div.form-enterprise.instance-details {
  grid-template-areas:
    'name     name'
    'version  region';
}

div.instance-details > div.name {
  grid-area: name;
}

div.instance-details > div.version {
  grid-area: version;
}

div.instance-details > div.region {
  grid-area: region;
}
